import * as React from "react";
import styles from "../style/components/ChooseLocationSearch.module.scss"
import ActivityIndicator from "./ActivityIndicator";
import {useEffect, useState} from "react";
import * as apiService from "../backend/apiService";
import {MapViewState} from "../pages/ChooseLocation";
import { ProcedureTypes} from "../pages/ChooseLocation";

import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import globalColors from "../style/globalColors";
import {Slider} from "@mui/material";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import PlaceIcon from "@mui/icons-material/Place";

type Props = {
    setCurrentCity: (cityResponse: apiService.CityResponse) => void;
    setCitySelected: (isCitySelected: boolean) => void;
    setCircleRadius: (radius: number) => void;
    updateCircle: (center: { lat: number, lng: number }, newRadius: number) => void;
    setViewState: ( value: {latitude: number, longitude: number, zoom: number} ) => void;
    currentCity?: apiService.CityResponse;
    circleRadius: number;
    viewState?: MapViewState;
    forceRadiusEditor?: boolean;
    inputRef?: React.RefObject<HTMLInputElement>;
}

enum ErrorTypes {
    FAILED_TO_SEARCH = "Failed to search",
    FAILED_TO_FETCH_CITY_DETAILS = "Failed to fetch city details",
    FAILED_TO_SUBMIT = "Failed to submit"
}

const ChooseLocationSearch: React.FC<Props> = ({setCurrentCity, setCitySelected, setCircleRadius, updateCircle, currentCity, circleRadius, viewState, forceRadiusEditor, inputRef}) => {
    const navigate = useNavigate();
    const {procedure} = useParams();
    const [searchParams] = useSearchParams();
    const loginType = searchParams.get('loginType');

    const [errorType, setErrorType] = useState<ErrorTypes | undefined>();

    const [awaitingSearchResults, setAwaitingSearchResults] = useState<boolean>(false);
    const [cities, setCities] = useState<apiService.CityResponse[]>([]);
    const [searchText, setSearchText] = useState<string>("");
    const [searchFocused, setSearchFocused] = useState<boolean>(false);
    const [toggleSlider, setToggleSlider] = useState<boolean>(false);
    const [nextButtonClicked, setNextButtonClicked] = useState<boolean>(false)

    const searchCities = async (text: string) => {
        setSearchText(text);
        setAwaitingSearchResults(true);
        apiService.fetchSearchCities(text).then(citySearchResults => {
            setAwaitingSearchResults(false);
            setCities(citySearchResults);
            //console.log("Search Results: " + JSON.stringify(citySearchResults));
        }).catch(error => {
            console.log(error)
        });
    }

    const handleCitySelect = (city: apiService.CityResponse) => {
        setCurrentCity(city);
        setCitySelected(true);
        setCircleRadius(15);
        updateCircle({lng: city.longitude, lat: city.latitude}, 15);
        setToggleSlider(true);
    }

    const handleChangeCity = () => {
        setToggleSlider(false);
        setCitySelected(false);
    }

    useEffect(() => {
        if (forceRadiusEditor) {
            setToggleSlider(true)
        }
    }, []);

    return(
        <div className={styles['main-wrapper']}>
            {!toggleSlider && (<div className={styles['city-search']}>
                    <div className={styles['search-container']}>
                        <input autoFocus={true} className={styles['search-bar']}
                               ref={inputRef}
                               type='text'
                               placeholder='Where will you explore local music?'
                               value={searchText}
                               onChange={(event) => searchCities(event.target.value)}
                               onFocus={() => setSearchFocused(true)}
                               onBlur={() => setSearchFocused(false)}
                        />
                        <button className={styles['search-btn']}><SearchRoundedIcon/></button>
                        {/*<button className={styles['search-btn']} type={'submit'}><ArrowForwardRoundedIcon/></button>*/}
                    </div>
                    {searchFocused && (cities.length >= 1 || awaitingSearchResults) && (
                        <div className={styles['search-results']}>
                            {awaitingSearchResults ? (
                                <button className={styles['city-result-btn']}>
                                    <ActivityIndicator color="white" width={"15px"} height={"15px"}/>
                                </button>
                            ) : (
                                cities.map((city, i) => (
                                    <button className={styles['city-result-btn']} key={i}
                                            onMouseDown={() => handleCitySelect(city)}>
                                        {`${city.name}, ${city?.zoneCode ? city.zoneCode : city.countryCode}`}
                                    </button>
                                ))
                            )}
                        </div>
                    )}
                </div>
            )}
            { toggleSlider && (
                <div className={styles['city-slider']}>
                    <div className={styles['slider-header']}>
                        <div className={styles["header-start"]}>
                            <PlaceIcon className={styles['pin-icon']} fontSize={"small"}/>
                            <h3 className={styles['city-title']}>
                                {currentCity?.name}, {currentCity?.zoneCode || currentCity?.countryCode}
                            </h3>
                        </div>
                        {!forceRadiusEditor && (
                            <div
                                className={styles["select-different-city"]}
                                onClick={handleChangeCity}
                            >
                                <ArrowBackRoundedIcon className={styles["icon"]} />
                                <h6>Choose a different city</h6>
                            </div>
                        )}
                    </div>
                    <div className={styles['slider-container']}>
                        <div className={styles['slider-controls']}>
                            <text>{circleRadius ? circleRadius : '0'} {circleRadius === 1 ? "mile" : "miles"}</text>
                            <Slider
                                aria-label={"Custom marks"}
                                value={circleRadius}
                                onChange={(event, value) => {
                                    updateCircle({
                                        lat: viewState?.latitude ? viewState.latitude : 0,
                                        lng: viewState?.longitude ? viewState.longitude : 0,
                                    }, value as number);
                                    setCircleRadius(Math.round(value as number))}
                                }
                                marks={[
                                    {value: 1},
                                    {value: 50}
                                ]}
                                max={50}
                                min={1}
                                valueLabelDisplay="off"
                                style={{color: globalColors.primary, width: "100%", paddingTop: 5}}
                            />
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default ChooseLocationSearch
