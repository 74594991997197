import React, {useState, useRef, useEffect} from "react";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import * as Store from "../backend/storage";
import { useUser } from '../hooks/UserContext';
import * as apiService from "../backend/apiService";

import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import {ChevronRight, KeyboardArrowRight} from "@mui/icons-material";
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import EditLocationRoundedIcon from '@mui/icons-material/EditLocationRounded';
import InterpreterModeIcon from '@mui/icons-material/InterpreterMode';
import GroupIcon from '@mui/icons-material/Group';
import EventIcon from '@mui/icons-material/Event';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

import banner from '../assets/banner.png';
import styles from "../style/components/PageHeader.module.scss";
import {useRecommendations} from "../hooks/RecommendationsContext";

export enum HeaderTypes {
    HOME = "home",
    LANDING = "landing",
    DEFAULT = "default" // Used for onboarding too
}

export enum PageTypes {
    RECOMMENDATIONS = "recommendations",
    SEARCH = "search",
    SETTINGS = "settings"
}

export enum ButtonSizes {
    SMALL = "small",
    MEDIUM = "medium",
    LARGE = "large"
}

type Props = {
    type?: HeaderTypes;
    isBackable?: boolean;
    setShowEditCitiesModal?: () => void;
    userImage?: string;
    backButtonSize?: ButtonSizes;
    backButtonString?: string;
    backButtonFunction?: () => void;
    continueButtonFunction?: () => void;
    continueDisabled?: boolean;
    continueText?: string;
};

const PageHeader: React.FC<Props> = ({
                                         type,
                                         isBackable = true,
                                         setShowEditCitiesModal,
                                         backButtonSize = ButtonSizes.MEDIUM,
                                         backButtonString,
                                         backButtonFunction,
                                         continueButtonFunction,
                                         continueDisabled = false,
                                         continueText
                                     }) => {

    const navigate = useNavigate();
    const location = useLocation();
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [showNavbar, setShowNavbar] = useState<boolean>(false);
    const dropdownRef = useRef<HTMLDivElement>(null);
    const [canGoBack, setCanGoBack] = useState(false);
    const { userInfo, setUserInfo } = useUser();
    const { hasRecs, setHasRecs } = useRecommendations();

    const noImage = 'https://localify-cdn.s3.amazonaws.com/assets/web_assets/NoImage.jpeg';

    useEffect(() => {
        setCanGoBack(window.history.length > 1);

        const handleClickOutside = (event: MouseEvent) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                setDropdownVisible(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, []);

    useEffect(() => {
        if (type === HeaderTypes.HOME && location.pathname !== "/user/settings") {
            apiService.fetchUserDetails()
                .then(details => setUserInfo(details))
                .catch(error => null); //It's fineeee
        }

        if (type === HeaderTypes.LANDING) {

        }
    }, []);


    const toggleDropdown = () => setDropdownVisible(!dropdownVisible);

    const handleShowNavbar = () => {
        setShowNavbar(!showNavbar);
    };

    const handleLogout = () => {
        Store.clearAuthToken();
        navigate('/login');
        setUserInfo(undefined);
        setHasRecs(false);
    };

    const handleBackButtonClick = () => {
        if (backButtonFunction) {
            backButtonFunction();
        }
        else if (canGoBack){
            navigate(-1);
        }
    }

    const backButton = () => (
        <div
            className={`${styles["back-button"]} ${backButtonString ? styles["button-has-string"] : ""}`}
            onClick={handleBackButtonClick}
        >
            <KeyboardArrowLeftIcon
                className={styles["icon"]}
                fontSize={backButtonSize}
            />
            {backButtonString && (
                <h6>{backButtonString}</h6>
            )}
        </div>
    );

    const continueButton = () => (
        <div
            className={`
                ${styles["continue-button"]} 
                ${backButtonString ? styles["button-has-string"] : ""}
                ${continueDisabled ? styles["disabled"] : ""}
            `}
            onClick={continueButtonFunction}
        >
            <h6>{continueText || "Continue"}</h6>
            <KeyboardArrowRight
                className={styles["icon"]}
                fontSize={backButtonSize}
            />
        </div>
    )

    if (type === HeaderTypes.LANDING) {
        return (
            <nav className={styles["navbar"]}>
                {isBackable && canGoBack && backButton()}
                {continueButtonFunction && continueButton()}
                {/* Apply the hamburger icon only to this page "landing-page-elements" */}
                <div className={`${styles["header-real-container"]} ${styles["landing-page-elements"]}`}>
                    <div
                        className={styles["navbar-logo"]}
                        onClick={() => navigate("/")}
                    >
                        <img
                            src={banner}
                            alt="Localify Logo"
                            className={styles["navbar-logo"]}
                        />
                    </div>

                    {/* Menu Icon for Mobile */}
                    <div className={styles["menu-icon"]} onClick={handleShowNavbar}>
                        {showNavbar ? <CloseIcon/> : <MenuIcon/>}
                    </div>

                    <div className={`${styles["nav-elements"]} ${showNavbar ? styles["active"] : ""}`}>
                        <ul>
                            <li className={styles["nav-bar-link"]}>
                                <NavLink to="" onClick={() => window.open("https://blog.localify.org", "_blank")}>
                                    <span>Blog</span>
                                    <div className={styles["mobile-only"]}>
                                        <ChevronRight fontSize="small"/>
                                    </div>
                                </NavLink>
                            </li>
                            <li className={styles["nav-bar-link"]}>
                                <NavLink to="/team/about-us">
                                    <span>About Us</span>
                                    <div className={styles["mobile-only"]}>
                                        <ChevronRight fontSize="small"/>
                                    </div>
                                </NavLink>
                            </li>

                            <li className={styles["login-button"]}>
                                {hasRecs ? (
                                    <NavLink to="/recommendations">Continue to Localify</NavLink>
                                ) : (
                                    <NavLink to="/login">Login</NavLink>
                                )}
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        );
    } else if (type === HeaderTypes.HOME && userInfo) {
        return (
            <nav className={styles.navbar}>
                {isBackable && canGoBack && backButton()}
                {continueButtonFunction && continueButton()}
                <div className={styles["header-real-container"]}>
                    <div className={styles["navbar-logo"]} onClick={() => navigate("/recommendations")}>
                        <img src={banner} alt="Localify Logo" className={styles["navbar-logo"]}/>
                    </div>
                    <div className={`${styles["nav-elements"]} ${showNavbar ? styles["active"] : ""}`}>
                        <ul>
                            <li className={styles["nav-bar-link"]}>
                                <NavLink to="/search">
                                    <SearchRoundedIcon className={styles.icon}/>
                                </NavLink>
                            </li>
                            {/* Profile Image with Dropdown Toggle */}
                            <li className={styles["nav-bar-link"]}>
                                <div className={styles["profile-container"]} onClick={toggleDropdown}>
                                    <img alt="User Profile" src={userInfo.spotifyProfileImage || userInfo.profileImage || noImage}
                                         className={styles["profile-image"]}/>
                                    {/* Dropdown Menu */}
                                    {dropdownVisible && (
                                        <div className={styles["dropdown-menu"]} ref={dropdownRef}>
                                            <div className={styles["dropdown-header"]}>
                                                <img src={userInfo.spotifyProfileImage || userInfo.profileImage || noImage} alt="User Profile"
                                                     className={styles["profile-image"]}/>
                                                <div className={styles["header-text"]}>
                                                    <span className={styles["user-name"]}>
                                                        {userInfo.name}
                                                    </span>
                                                    <span
                                                        className={styles["edit-profile-text"]}
                                                        onClick={() => navigate("/user/settings")}
                                                    >Edit Profile</span>
                                                </div>
                                            </div>
                                            {setShowEditCitiesModal && (
                                                <NavLink
                                                    to=""
                                                    className={styles["dropdown-item"]}
                                                    onClick={(event) => {
                                                        event.preventDefault(); // Prevents NavLink from navigating immediately
                                                        setShowEditCitiesModal();
                                                    }}
                                                >
                                                    <EditLocationRoundedIcon className={styles["dropdown-icon"]}/>
                                                    <span>Change City</span>
                                                </NavLink>
                                            )}
                                            <NavLink to="/saved/favorite-artists" className={styles["dropdown-item"]}>
                                                <InterpreterModeIcon className={styles["dropdown-icon"]}/>
                                                <span>Your Artists</span>
                                            </NavLink>
                                            <NavLink to="/saved/favorite-events-upcoming"
                                                     className={styles["dropdown-item"]}>
                                                <EventIcon className={styles["dropdown-icon"]}/>
                                                <span>Your Events</span>
                                            </NavLink>
                                            <hr className={styles["dropdown-divider"]}/>
                                            <NavLink to=""
                                                     onClick={() => window.open("https://blog.localify.org", "_blank")}
                                                     className={styles["dropdown-item"]}>
                                                <QuestionAnswerIcon className={styles["dropdown-icon"]}/>
                                                <span>Blog</span>
                                            </NavLink>
                                            <NavLink to="/team/about-us"
                                                     className={styles["dropdown-item"]}>
                                                <GroupIcon className={styles["dropdown-icon"]}/>
                                                <span>About Us</span>
                                            </NavLink>
                                            <hr className={styles["dropdown-divider"]}/>
                                            <NavLink
                                                to="/login"
                                                onClick={(event) => {
                                                    event.preventDefault(); // Prevents NavLink from navigating immediately
                                                    handleLogout();
                                                }}
                                                className={styles["dropdown-item"]}>
                                                <ExitToAppIcon className={styles["dropdown-icon"]}/>
                                                <span>Sign Out</span>
                                            </NavLink>
                                        </div>
                                    )}
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        );
    } else if (type === HeaderTypes.DEFAULT) {
        return (
            <nav className={styles["navbar"]}>
                {isBackable && canGoBack && backButton()}
                {continueButtonFunction && continueButton()}
                <div className={styles["header-real-container"]}>
                    <div
                        className={styles["navbar-logo"]}
                        onClick={() => navigate("/")}
                    >
                        <img
                            src={banner}
                            alt="Localify Logo"
                            className={styles["navbar-logo"]}
                        />
                    </div>
                    <div/>
                </div>
            </nav>
        );
    } else {
        return null;
    }
}

export default PageHeader;
